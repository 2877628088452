import { VueConstructor } from "vue";
import { Permission, PermissionKey, User } from "@/resources/interfaces";

const globalVars = {
  install(Vue: VueConstructor) {
    // global library setup
    Object.defineProperty(Vue.prototype, "$electron", {
      get() {
        //@ts-ignore
        return window.remote;
      }
    });

    Object.defineProperty(Vue.prototype, "$version", {
      get() {
        return process.env.VERSION;
      }
    });

    Object.defineProperty(Vue.prototype, "$webSocketConnected", {
      get() {
        if (!this.$root.webSocketConnected)
          this.$root.webSocketConnected = false;
        return this.$root.webSocketConnected;
      },
      set(val) {
        this.$root.webSocketConnected = val;
      }
    });

    Object.defineProperty(Vue.prototype, "$config", {
      get() {
        return this.$root.config;
      },
      set(v) {
        this.$root.config = v;
      }
    });

    const can = function(this: User, ...permissions: PermissionKey[]) {
      return permissions.every(permission =>
        this.role?.permissions?.includes(Permission[permission])
      );
    };

    Object.defineProperty(Vue.prototype, "$user", {
      get() {
        this.$root.config.user.can = can;
        return this.$root.config.user;
      },
      set(v) {
        this.$root.config.user = v;
      }
    });

    Object.defineProperty(Vue.prototype, "$stores", {
      get() {
        return this.$root.config.stores;
      }
    });

    Object.defineProperty(Vue.prototype, "$roles", {
      get() {
        return this.$root.config.roles;
      }
    });

    Object.defineProperty(Vue.prototype, "$cardTypes", {
      get() {
        return this.$root.config.cardTypes;
      }
    });

    Object.defineProperty(Vue.prototype, "$coupons", {
      get() {
        return this.$root.config.coupons;
      }
    });

    Object.defineProperty(Vue.prototype, "$bookingStatusNames", {
      get() {
        return {
          pending: "待付款",
          booked: "已确认",
          // eslint-disable-next-line
          in_service: "进行中",
          // eslint-disable-next-line
          pending_refund: "待撤销",
          finished: "已完成",
          canceled: "已取消"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$cardTypeNames", {
      get() {
        return {
          times: "次卡",
          period: "时效卡",
          balance: "储值卡",
          coupon: "优惠券",
          rights: "权益卡",
          partner: "合作卡"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$cardStatusNames", {
      get() {
        return {
          pending: "待付款",
          valid: "待激活",
          activated: "已激活",
          expired: "已失效",
          canceled: "已取消"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$gatewayNames", {
      get() {
        return {
          shouqianba: "收钱吧",
          wechatpay: "微信支付",
          alipay: "支付宝",
          byte: "字节担保",
          pos: "对公",
          cash: "现金",
          mallpos: "商场POS",
          dianping: "点评POS",
          card: "会员卡",
          contract: "合作卡",
          couponcard: "优惠券",
          mall: "在线商城",
          coupon: "优惠",
          balance: "账户余额",
          points: "账户积分",
          pr: "市场公关",
          agency: "三方代收",
          ar: "应收账款",
          cr: "客户关系",
          internal: "内部结算",
          scan: "扫码"
          // unionpay: "银联"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$sceneNames", {
      get() {
        return {
          play: "门票",
          food: "餐饮",
          party: "派对",
          event: "Lab",
          retail: "零售",
          care: "托管",
          card: "卡券",
          balance: "充值",
          period: "时效卡",
          gift: "礼品",
          mall: "电商",
          other: "其他",
          cost: "成本",
          expense: "费用"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$productTypeNames", {
      get() {
        return {
          retail: "零售",
          food: "餐品",
          ingredient: "原料",
          assets: "固定资产",
          consumables: "易耗品",
          toy: "内场玩具",
          lab: "Lab活动"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$stockLogStatusNames", {
      get() {
        return {
          new: "新申请",
          requested: "待审批",
          approved: "已审批",
          paid: "已付款",
          sent: "待收货",
          received: "已收货",
          // eslint-disable-next-line
          in_stock: "已入库"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$logTypeNames", {
      get() {
        return {
          remarks: "备注",
          enter: "入场",
          exit: "离场",
          "card-transfer": "卡券转赠",
          "card-change-store": "会员卡转门店",
          refund: "撤销订单/退款",
          merge: "合并客户"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$staffStatusNames", {
      get() {
        return {
          onboard: "正式在职",
          probation: "试用期",
          resigned: "已离职"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$permissionNames", {
      get() {
        return {
          develop: "开发",
          bossboard: "Boss面板",
          dashboard: "数据统计",
          "play booking": "票务订单",
          "event booking": "活动订单",
          "care booking": "托管订单",
          "food booking": "餐饮订单",
          "gift booking": "礼品订单",
          "party booking": "派对订单",
          "other booking": "其他订单",
          "booking all store": "多门店",
          "booking create": "录入订单",
          "booking cancel review": "审核取消订单",
          card: "卡券销售",
          "card sell store": "售卡（门店）",
          "card sell all": "售卡（全部）",
          "card issue survey": "发券（满意度调查）",
          customer: "客户",
          payment: "支付明细",
          "payment download": "支付明细下载",
          "payment last week": "支付明细（上周）",
          "payment last month": "支付明细（上月）",
          "payment all date": "支付明细（全部日期）",
          cashier: "出纳",
          "no stock": "禁用库存采购",
          "card-type": "卡片种类",
          coupon: "优惠管理",
          post: "内容管理",
          event: "活动管理",
          gift: "礼品管理",
          product: "商品管理",
          store: "门店管理",
          staff: "职员管理",
          role: "权限管理",
          config: "系统配置",
          huiyuan: "会员系统"
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$noop", {
      get() {
        return () => {};
      }
    });

    Object.defineProperty(Vue.prototype, "$clipboard", {
      get() {
        return (v: string, name: string = "文本") => {
          this.$copyText(v);
          this.$notify({
            message: `${name}已复制`,
            icon: "check",
            type: "success"
          });
        };
      }
    });
  }
};

export default globalVars;
