



































import Vue from "vue";
import Component from "vue-class-component";
import { File as FileDoc } from "@/resources/interfaces";
import { http } from "@/resources";
import { Prop } from "vue-property-decorator";

@Component
export default class FilesCard extends Vue {
  @Prop({ required: true })
  value!: FileDoc[];

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  preview = "";

  domFiles: File[] = [];
  uploadingFiles = false;
  accepts = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/zip"
  ];
  draggingover = false;

  previewingFile: null | FileDoc = null;

  get files() {
    return this.value || [];
  }

  set files(v) {
    this.$emit("input", v);
  }

  dragover() {
    this.draggingover = true;
  }

  dragleave() {
    this.draggingover = false;
  }

  addDropFile(e: DragEvent) {
    console.log("addDropFile", e);
    this.draggingover = false;
    if (!e.dataTransfer) return;
    const files = Array.from(e.dataTransfer.files).filter(f =>
      this.accepts.includes(f.type)
    );
    files.forEach(file => {
      this.uploadFile(file);
    });
    this.domFiles = this.domFiles.concat(files);
  }

  onFileChange(e: any) {
    console.log("onFileChange", e);
    let files = (e.target?.files || e.dataTransfer?.files) as FileList;
    if (!files?.length) return;
    Array.from(files).forEach(file => {
      this.uploadFile(file);
    });
  }

  createImage(file: Blob) {
    let reader = new FileReader();
    let vm = this;

    reader.onload = (e: any) => {
      vm.preview = e.target.result;
    };
    reader.readAsDataURL(file);
    this.uploadFile(file);
  }

  async uploadFile(file: Blob) {
    const formData = new FormData();
    formData.append("file", file);
    const fileObject: FileDoc = (
      await http.post("file", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
    this.files.push(fileObject);
    (this.$refs["file-input"] as HTMLInputElement).value = "";
    this.$emit("input", this.files);
  }

  removeFile(file: FileDoc) {
    this.files = this.files.filter(f => f.id !== file.id);
  }
}
