








































import Vue from "vue";
import Component from "vue-class-component";
import { File } from "@/resources/interfaces";
import { http } from "@/resources";
import { Prop } from "vue-property-decorator";

@Component({
  name: "poster",
  props: {
    height: String,
    circle: { default: false, type: Boolean },
    placeholder: { default: "/img/image_placeholder.jpg", type: String },
    disabled: { default: false, type: Boolean },
    showButtons: { default: true, type: Boolean }
  }
})
export default class Poster extends Vue {
  @Prop()
  value!: string;

  @Prop({ type: Boolean, default: false })
  resetOnUpload!: boolean;

  preview = "";

  get isVideo() {
    const suffix = this.value?.match(/\.?.*\.(.*?)$/)?.[1] || "";
    return ["mp4", "mov"].includes(suffix.toLowerCase());
  }

  imageClick() {
    if (this.value) {
      window.open(this.value);
    } else {
      this.openFileInput();
    }
  }

  openFileInput() {
    (this.$refs["file-input"] as HTMLInputElement).click();
  }

  onFileChange(e: any) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
  }
  createImage(file: Blob) {
    let reader = new FileReader();
    let vm = this;

    reader.onload = (e: any) => {
      vm.preview = e.target.result;
    };
    reader.readAsDataURL(file);
    this.uploadImage(file);
  }
  async uploadImage(file: Blob) {
    const formData = new FormData();
    formData.append("file", file);
    const fileObject: File = (
      await http.post("file", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
    this.$emit("input", fileObject.url);
    if (this.resetOnUpload) {
      (this.$refs["file-input"] as HTMLInputElement).value = "";
      this.preview = "";
    }
  }
  removeImage() {
    this.preview = "";
    this.$emit("input", null);
    (this.$refs["file-input"] as HTMLInputElement).value = "";
  }
}
