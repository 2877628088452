


























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Slider, SliderItem } from "vue-easy-slider";
import Modal from "./Modal.vue";
import {
  // loadSsdMobilenetv1Model,
  detectAllFaces,
  FaceDetection,
  TinyFaceDetectorOptions,
  loadTinyFaceDetectorModel
} from "face-api.js";
import { http } from "@/resources";
import { File as FileDoc, Booking } from "@/resources/interfaces";

@Component({
  components: {
    Modal,
    Slider,
    SliderItem
  }
})
export default class FacesSnapshotModal extends Vue {
  stream?: MediaStream;
  modelLoaded = false;
  detecting = false;
  cameras: MediaDeviceInfo[] = [];
  selectedCamera: MediaDeviceInfo | null = null;
  photoPreviewUrl: string | null = null;
  photoFile: File | null = null;
  detections: FaceDetection[] = [];
  faces: { url: string; file?: File; selected: boolean }[] = [];
  state: "capture" | "preview" | "view" = "view";

  @Prop({ required: true })
  booking!: Booking;

  get selectedFaces() {
    return this.faces.filter(face => face.selected);
  }

  @Watch("state") onStateChange() {
    console.log("state:", this.state);
    if (this.state === "capture") {
      this.startStream();
    } else {
      this.stopStream(true);
    }
  }

  async loadModels() {
    // await loadSsdMobilenetv1Model("https://cdn.mini-mars.com/face-models/");
    await loadTinyFaceDetectorModel("https://cdn.mini-mars.com/face-models/");
    this.modelLoaded = true;
    console.log("Model loaded.");
  }

  switchCamera() {
    const index = this.cameras.findIndex(
      c => c.deviceId === this.selectedCamera?.deviceId
    );
    if (index < this.cameras.length) {
      this.selectedCamera = this.cameras[index + 1];
    } else {
      this.selectedCamera = null;
    }
    console.log("selected:", this.selectedCamera?.label);
    if (this.selectedCamera) {
      window.localStorage.setItem(
        "selectedCameraDeviceId",
        this.selectedCamera.deviceId
      );
    } else {
      window.localStorage.removeItem("selectedCameraDeviceId");
    }
    if (this.state === "capture") {
      this.stopStream();
      this.startStream();
    }
  }

  async snapshot() {
    const video = this.$refs.video as HTMLVideoElement;
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    if (this.photoPreviewUrl) {
      this.photoPreviewUrl = null;
      this.photoFile = null;
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")
      ?.drawImage(video, 0, 0, canvas.width, canvas.height);

    console.log("image drew");

    this.photoPreviewUrl = canvas.toDataURL("image/jpeg");
    canvas.toBlob(
      blob => {
        if (!blob) return;
        const file = new File([blob], `photo-${Date.now()}.jpg`);
        this.photoFile = file;
      },
      "image/jpeg",
      0.6
    );
    this.state = "preview";
    setTimeout(() => {
      const previewImage = this.$refs.previewImage as HTMLImageElement;
      this.detectFaces(previewImage);
    });
  }

  async detectFaces(img: HTMLImageElement) {
    this.detecting = true;
    console.log(`detect face from canvas w:${img.width} h:${img.height}`);
    const detections = await detectAllFaces(img, new TinyFaceDetectorOptions());
    if (!detections.length) {
      this.$notify({
        message: `没有检测到人脸，请重新拍摄`,
        icon: "close",
        type: "warning"
      });
      this.detecting = false;
      this.resetSnapshot();
      return;
    }
    this.detections = detections.filter(d => d.score >= 0.8);
    if (!this.detections.length) {
      this.$notify({
        message: `拍摄的人脸过小、过暗或过于倾斜，请重新拍摄`,
        icon: "close",
        type: "warning"
      });
      this.detecting = false;
      this.resetSnapshot();
      return;
    }
    if (detections.length !== this.detections.length) {
      this.$notify({
        message: `有部分人脸过小、过暗或过于倾斜，请追加拍摄`,
        icon: "close",
        type: "warning"
      });
    }
    await this.$nextTick();
    this.detecting = false;
    this.detections.forEach((d, i) => {
      const refs = this.$refs["face-" + i] as Element[];
      const faceCanvas = refs[0] as HTMLCanvasElement;
      faceCanvas.width = d.box.width;
      faceCanvas.height = d.box.height;
      faceCanvas
        .getContext("2d")
        ?.drawImage(
          img,
          d.box.x,
          d.box.y,
          d.box.width,
          d.box.height,
          0,
          0,
          faceCanvas.width,
          faceCanvas.height
        );
      const url = faceCanvas.toDataURL("image/jpeg");
      this.faces.push({ url, selected: true });
      faceCanvas.toBlob(blob => {
        if (!blob) return;
        const file = new File([blob], `face-${Date.now()}.jpg`);
        this.faces[i].file = file;
      }, "image/jpeg");
    });
  }

  toggleSelectFace(index: number) {
    this.$set(this.faces, index, {
      ...this.faces[index],
      selected: !this.faces[index].selected
    });
  }

  resetSnapshot() {
    this.state = "capture";
    this.photoFile = null;
    this.photoPreviewUrl = null;
    this.faces = [];
  }

  async uploadImage(file: Blob) {
    const formData = new FormData();
    formData.append("file", file);
    const fileObject: FileDoc = (
      await http.post("file", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
    return fileObject;
  }

  async save() {
    if (!this.photoFile) return;
    const [photoFile, faceFiles] = await Promise.all([
      this.uploadImage(this.photoFile),
      Promise.all(
        this.selectedFaces.map(face => {
          if (!face.file) return;
          return this.uploadImage(face.file);
        })
      )
    ]);
    this.$emit("photo-upload", {
      photo: photoFile.url,
      faces: faceFiles.map(file => file?.url)
    });
    this.close();
  }

  close() {
    this.stopStream(true);
    this.$emit("close");
  }

  async getVideoInputDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.cameras = devices.filter(d => d.kind === "videoinput");
    const selectedCamera = this.cameras.find(
      d => d.deviceId === window.localStorage.getItem("selectedCameraDeviceId")
    );
    if (selectedCamera) {
      console.log("found selected camera", selectedCamera.label);
      this.selectedCamera = selectedCamera;
    }
  }

  startStream() {
    const video = this.$refs.video as HTMLVideoElement;
    navigator.mediaDevices
      .getUserMedia({
        video: this.selectedCamera
          ? { deviceId: { exact: this.selectedCamera.deviceId } }
          : { facingMode: "environment" },
        audio: false
      })
      .then(stream => {
        this.stream = stream;
        (video as HTMLVideoElement).srcObject = stream;
      })
      .catch(e => {
        console.log(`navigator.getUserMedia error: ${e}`);
      });
  }

  stopStream(nonReception = false) {
    if (nonReception && !this.$user.can("BOOKING_ALL_STORE")) return;
    this.stream?.getTracks().forEach(track => track.stop());
  }

  async mounted() {
    this.loadModels();
    await this.getVideoInputDevices();
    if (!this.booking.photos?.length) {
      this.state = "capture";
    }
  }

  async destroyed() {
    this.stopStream(true);
  }
}
