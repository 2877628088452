import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

import { RouteConfig } from "vue-router";

// Dashboard pages
const Welcome = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Welcome.vue");
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Dashboard.vue");
const BossBoard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/BossBoard.vue");
const MyBoard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/MyBoard.vue");
// Pages
const UserProfile = () =>
  import(
    /* webpackChunkName: "user" */ "@/pages/Dashboard/User/UserProfile.vue"
  );
const UserList = () =>
  import(/* webpackChunkName: "user" */ "@/pages/Dashboard/User/UserList.vue");
const StaffProfile = () =>
  import(
    /* webpackChunkName: "user" */ "@/pages/Dashboard/User/StaffProfile.vue"
  );
const StaffUserList = () =>
  import(/* webpackChunkName: "user" */ "@/pages/Dashboard/User/StaffList.vue");
const StaffDetail = () =>
  import(
    /* webpackChunkName: "user" */ "@/pages/Dashboard/Staff/StaffDetail.vue"
  );
const StaffList = () =>
  import(
    /* webpackChunkName: "user" */ "@/pages/Dashboard/Staff/StaffList.vue"
  );
const BookingDetail = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/BookingDetail.vue"
  );
const FoodCashier = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/FoodCashier.vue"
  );
const FoodQueue = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/FoodQueue.vue"
  );
const FoodTodo = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/FoodTodo.vue"
  );
const RetailCashier = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/RetailCashier.vue"
  );
const BookingItemsStats = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/BookingItemsStats.vue"
  );
const BookingList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Booking/BookingList.vue"
  );
const CardTypeDetail = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/CardType/CardTypeDetail.vue"
  );
const CardTypeList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/CardType/CardTypeList.vue"
  );
const CardList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Card/CardList.vue"
  );
const CouponDetail = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Coupon/CouponDetail.vue"
  );
const CouponList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Coupon/CouponList.vue"
  );
const EventDetail = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Event/EventDetail.vue"
  );
const EventList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Event/EventList.vue"
  );
const GiftDetail = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Gift/GiftDetail.vue"
  );
const GiftList = () =>
  import(
    /* webpackChunkName: "booking" */ "@/pages/Dashboard/Gift/GiftList.vue"
  );
// const Payment = () => import("@/pages/Dashboard/Payment/PaymentDetail.vue");
const PaymentList = () =>
  import(
    /* webpackChunkName: "payment" */ "@/pages/Dashboard/Payment/PaymentList.vue"
  );
const PostDetail = () =>
  import(
    /* webpackChunkName: "misc" */ "@/pages/Dashboard/Post/PostDetail.vue"
  );
const PostList = () =>
  import(/* webpackChunkName: "misc" */ "@/pages/Dashboard/Post/PostList.vue");
const ProductList = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/ProductList.vue"
  );
const ProductCategoryList = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/ProductCategoryList.vue"
  );
const ProductDetail = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/ProductDetail.vue"
  );
const StoreDetail = () =>
  import(
    /* webpackChunkName: "misc" */ "@/pages/Dashboard/Store/StoreDetail.vue"
  );
const StoreList = () =>
  import(
    /* webpackChunkName: "misc" */ "@/pages/Dashboard/Store/StoreList.vue"
  );
const StockList = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/StockList.vue"
  );
const StockLogList = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/StockLogList.vue"
  );
const StockLogDetail = () =>
  import(
    /* webpackChunkName: "product" */ "@/pages/Dashboard/Product/StockLogDetail.vue"
  );
const RoleDetail = () =>
  import(
    /* webpackChunkName: "misc" */ "@/pages/Dashboard/User/RoleDetail.vue"
  );
const RoleList = () =>
  import(/* webpackChunkName: "misc" */ "@/pages/Dashboard/User/RoleList.vue");
const ConfigList = () =>
  import(
    /* webpackChunkName: "misc" */ "@/pages/Dashboard/Config/ConfigList.vue"
  );
const Config = () =>
  import(/* webpackChunkName: "misc" */ "@/pages/Dashboard/Config/Config.vue");
const Login = () =>
  import(/* webpackChunkName: "misc" */ "@/pages/Dashboard/Pages/Login.vue");

const bookingMenu: RouteConfig = {
  path: "/booking",
  component: DashboardLayout,
  redirect: "/booking/play",
  children: [
    {
      path: "food-cashier",
      name: "点餐",
      component: FoodCashier,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "lab-cashier",
      name: "MARS LAB 收银台",
      component: FoodCashier,
      props: { type: "event" },
      meta: {
        keepAlive: true
      }
    },
    {
      path: "food-queue",
      name: "排位",
      component: FoodQueue,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "food-todo",
      name: "划单",
      component: FoodTodo,
      meta: {
        keepAlive: true,
        hideTopNavBar: true
      }
    },
    {
      path: "retail-cashier",
      name: "零售",
      component: RetailCashier,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "items-stats",
      name: "商品统计",
      component: BookingItemsStats,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":type/add",
      name: "创建订单",
      component: BookingDetail,
      props: {
        add: true
      },
      meta: {
        keepAlive: true
      }
    },
    {
      alias: "play",
      path: ":type",
      name: "订单列表",
      component: BookingList,
      meta: {
        keepAlive: true
      }
    },
    {
      alias: "event",
      path: ":type",
      name: "订单列表",
      component: BookingList,
      meta: {
        keepAlive: true
      }
    },
    {
      alias: "gift",
      path: ":type",
      name: "订单列表",
      component: BookingList,
      meta: {
        keepAlive: true
      }
    },
    {
      alias: "food",
      path: ":type",
      name: "订单列表",
      component: BookingList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":type/:id",
      name: "订单详情",
      component: BookingDetail
    }
  ]
};

const cardMenu: RouteConfig = {
  path: "/card",
  component: DashboardLayout,
  redirect: "/card/index",
  children: [
    {
      path: "index",
      name: "卡券列表",
      component: CardList,
      meta: {
        keepAlive: true
      }
    }
  ]
};

const cardTypeMenu: RouteConfig = {
  path: "/card-type",
  component: DashboardLayout,
  redirect: "/card-type/index",
  children: [
    {
      path: "index",
      name: "卡种列表",
      component: CardTypeList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "卡种详情",
      component: CardTypeDetail
    }
  ]
};

const couponMenu: RouteConfig = {
  path: "/coupon",
  component: DashboardLayout,
  redirect: "/coupon/index",
  children: [
    {
      path: "index",
      name: "优惠列表",
      component: CouponList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "优惠详情",
      component: CouponDetail
    }
  ]
};

const eventMenu: RouteConfig = {
  path: "/event",
  component: DashboardLayout,
  redirect: "/event/index",
  children: [
    {
      path: "index",
      name: "活动列表",
      component: EventList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "活动详情",
      component: EventDetail
    }
  ]
};

const giftMenu: RouteConfig = {
  path: "/gift",
  component: DashboardLayout,
  redirect: "/gift/index",
  children: [
    {
      path: "index",
      name: "礼品列表",
      component: GiftList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "礼品详情",
      component: GiftDetail
    }
  ]
};

const paymentMenu: RouteConfig = {
  path: "/payment",
  component: DashboardLayout,
  redirect: "/payment/index",
  children: [
    {
      path: "index",
      name: "支付明细",
      component: PaymentList,
      meta: {
        keepAlive: true
      }
    }
  ]
};

const postMenu: RouteConfig = {
  path: "/post",
  component: DashboardLayout,
  redirect: "/post/index",
  children: [
    {
      path: "index",
      name: "内容列表",
      component: PostList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "内容详情",
      component: PostDetail
    }
  ]
};

const productMenu: RouteConfig = {
  path: "/product",
  component: DashboardLayout,
  redirect: "/product/index",
  children: [
    {
      path: "index",
      name: "商品列表",
      component: ProductList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "category",
      name: "商品分类",
      component: ProductCategoryList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "商品详情",
      component: ProductDetail
    }
  ]
};

const staffMenu: RouteConfig = {
  path: "/staff",
  component: DashboardLayout,
  redirect: "/staff/index",
  children: [
    {
      path: "index",
      name: "职员列表",
      component: StaffList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "职员详情",
      component: StaffDetail
    }
  ]
};

const stockMenu: RouteConfig = {
  path: "/stock",
  component: DashboardLayout,
  redirect: "/stock/index",
  children: [
    {
      path: "index",
      name: "库存",
      component: StockList,
      meta: {
        keepAlive: true
      }
    }
  ]
};

const stockLogMenu: RouteConfig = {
  path: "/stock-log",
  component: DashboardLayout,
  redirect: "/stock-log/index",
  children: [
    {
      path: "index",
      name: "采购调拨列表",
      component: StockLogList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "add",
      name: "创建采购调拨单",
      component: StockLogDetail,
      props: { add: true }
    },
    {
      path: ":id",
      name: "采购调拨单",
      component: StockLogDetail,
      meta: { keepAlive: true }
    }
  ]
};

const storeMenu: RouteConfig = {
  path: "/store",
  component: DashboardLayout,
  redirect: "/store/index",
  children: [
    {
      path: "index",
      name: "门店明细",
      component: StoreList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":id",
      name: "门店详情",
      component: StoreDetail
    }
  ]
};

const configMenu: RouteConfig = {
  path: "/config",
  component: DashboardLayout,
  redirect: "/config/index",
  children: [
    {
      path: "index",
      name: "配置列表",
      component: ConfigList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: ":key",
      name: "配置详情",
      component: Config
    }
  ]
};

const userMenu: RouteConfig = {
  path: "/user",
  component: DashboardLayout,
  children: [
    {
      path: "/profile",
      name: "用户列表",
      component: StaffUserList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/profile/add",
      name: "创建用户",
      component: StaffProfile,
      props: {
        add: true
      },
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/profile/:id",
      name: "职员详情",
      component: StaffProfile
    },
    {
      path: "/user",
      name: "客户查询",
      component: UserList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/user/add",
      name: "创建客户",
      component: UserProfile,
      props: {
        add: true
      },
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/user/:id",
      name: "客户详情",
      component: UserProfile,
      meta: {
        keepAlive: true
      }
    }
  ]
};

const roleMenu: RouteConfig = {
  path: "/role",
  component: DashboardLayout,
  children: [
    {
      path: "/role",
      name: "角色列表",
      component: RoleList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/role/add",
      name: "创建角色",
      component: RoleDetail,
      props: {
        add: true
      },
      meta: {
        keepAlive: true
      }
    },
    {
      path: "/role/:id",
      name: "角色详情",
      component: RoleDetail
    }
  ]
};

const authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "用户登录",
      component: Login
    }
  ]
};

const routes: RouteConfig[] = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  bookingMenu,
  cardMenu,
  cardTypeMenu,
  couponMenu,
  eventMenu,
  giftMenu,
  paymentMenu,
  postMenu,
  productMenu,
  staffMenu,
  stockMenu,
  stockLogMenu,
  storeMenu,
  configMenu,
  userMenu,
  roleMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "welcome",
        name: "Welcome",
        component: Welcome
      },
      {
        path: "dashboard",
        name: "数据统计",
        component: Dashboard,
        meta: { keepAlive: true }
      },
      {
        path: "boss-board",
        name: "Boss Board",
        component: BossBoard,
        meta: { keepAlive: true }
      },
      {
        path: "my-board",
        name: "My Board",
        component: MyBoard,
        meta: { keepAlive: true }
      }
    ]
  }
];

export default routes;
