var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',[_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.bold() },on:{"click":commands.bold}},[_c('md-icon',[_vm._v("format_bold")])],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.italic() },on:{"click":commands.italic}},[_c('md-icon',[_vm._v("format_italic")])],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.underline() },on:{"click":commands.underline}},[_c('md-icon',[_vm._v("format_underline")])],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.strike() },on:{"click":commands.strike}},[_c('md-icon',[_vm._v("format_clear")])],1),_c('md-menu',{staticStyle:{"margin":"0.3125rem 1px"}},[_c('md-button',{staticClass:"md-simple md-sm md-just-icon",attrs:{"md-menu-trigger":""}},[_c('md-icon',[_vm._v("format_size")])],1),_c('md-menu-content',[_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{
              'md-primary': isActive.heading({ level: 1 })
            },on:{"click":function($event){return commands.heading({ level: 1 })}}},[_c('span',[_vm._v("H1")])]),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{
              'md-primary': isActive.heading({ level: 2 })
            },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('span',[_vm._v("H2")])]),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{
              'md-primary': isActive.heading({ level: 3 })
            },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('span',[_vm._v("H3")])])],1)],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('md-icon',[_vm._v("format_list_bulleted")])],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('md-icon',[_vm._v("format_list_numbered")])],1),_c('md-button',{staticClass:"md-simple md-sm md-just-icon",class:{ 'md-primary': isActive.blockquote() },on:{"click":commands.blockquote}},[_c('md-icon',[_vm._v("format_quote")])],1)],1)]}}])}),_c('editor-content',{staticClass:"content",attrs:{"editor":_vm.editor,"content":_vm.value}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }