














































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { BookingsCard, CardsCard, PaymentsCard } from "@/components";
import { confirm, promptInput } from "@/helpers/sweetAlert";
import { PaymentResource, CardResource } from "@/resources";
import {
  CardType,
  Payment,
  Card,
  User,
  CardStatus,
  Scene,
  Booking,
  BookingStatus,
  PaymentGatewayGroup,
  PaymentGateway
} from "@/resources/interfaces";
import PaymentDialog from "./PaymentDialog.vue";
import { watchPayments } from "@/helpers/watchPayment";

@Component({
  components: {
    BookingsCard,
    CardsCard,
    PaymentsCard,
    PaymentDialog
  }
})
export default class Membership extends Vue {
  @Prop({ required: true })
  customer!: User;

  @Prop()
  payments?: Payment[];

  @Prop()
  booking?: Booking[];

  @Prop({ default: true })
  allowBuyCard!: boolean;

  @Prop({ type: Date, default: () => new Date() })
  loaded!: Date;

  cards: Card[] = [];

  selectedCardId = "";

  // @Prop({ default: () => [] })
  paymentItems: Payment[] | null = null;

  filterCardTypeTerm = "";

  selectedCardType: CardType | null = null;
  selectedCardCoupon: Card | null = null;

  submitLocked = false;

  get memberCards() {
    return this.cards.filter(c => c.type !== "coupon");
  }

  get couponCards() {
    return this.cards.filter(c => c.type === "coupon");
  }

  get filteredCardTypes() {
    if (!this.filterCardTypeTerm) return this.$cardTypes;
    return this.$cardTypes.filter(
      ct =>
        ct.slug.toLowerCase().includes(this.filterCardTypeTerm.toLowerCase()) ||
        ct.title.toLowerCase().includes(this.filterCardTypeTerm.toLowerCase())
    );
  }

  get selectedCardTypeScene(): Scene | undefined {
    if (!this.selectedCardType) {
      return undefined;
    }
    switch (this.selectedCardType.type) {
      case Scene.PERIOD:
      case Scene.BALANCE:
        return this.selectedCardType.type;
      default:
        return Scene.CARD;
    }
  }

  async getCardPayments() {
    if (!this.customer.id) return;
    this.paymentItems = await PaymentResource.query({
      customer: this.customer.id,
      scene: [Scene.CARD, Scene.BALANCE, Scene.PERIOD, Scene.MALL].join(",")
    });
    if (!this.$user.can("DEVELOP") && !this.$user.can("CASHIER")) {
      this.paymentItems = this.paymentItems.filter(
        p =>
          ![
            PaymentGateway.Card,
            PaymentGateway.Contract,
            PaymentGateway.CouponCard
          ].includes(p.gateway)
      );
    }
  }

  async getCards() {
    this.cards = await CardResource.query({
      customer: this.customer.id,
      limit: false
    });
  }

  selectCardType(cardType: CardType) {
    this.selectedCardType = { ...cardType };

    const couponSceneMap: Record<string, Scene> = {
      times: Scene.CARD,
      balance: Scene.BALANCE,
      period: Scene.PERIOD
    };

    const couponScene = couponSceneMap[cardType.type] as Scene | undefined;

    if (couponScene) {
      const cardCoupon = this.cards?.find(
        card =>
          card.type === "coupon" &&
          card.status === "activated" &&
          card.scenes.includes(couponScene)
      );
      if (
        cardCoupon &&
        (!cardCoupon.overPrice ||
          this.selectedCardType.price >= cardCoupon.overPrice) &&
        (!cardCoupon.productNos?.length ||
          cardCoupon.productNos.includes(this.selectedCardType.slug))
      ) {
        this.selectedCardCoupon = cardCoupon;
        if (cardCoupon.discountPrice) {
          this.selectedCardType.price = +(
            this.selectedCardType.price - (cardCoupon.discountPrice || 0)
          ).toFixed(2);
        } else if (cardCoupon.discountRate) {
          this.selectedCardType.price = +(
            this.selectedCardType.price *
            (1 - cardCoupon.discountRate)
          ).toFixed(2);
        }
      }
    }
  }

  async createCard(paymentGroups: PaymentGatewayGroup[]) {
    this.submitLocked = true;
    try {
      const card = await CardResource.save(
        // @ts-ignore
        { customer: this.customer.id, slug: this.selectedCardType?.slug },
        {
          paymentGateways: paymentGroups
            .filter(g => g.amount)
            .map(g => {
              let seg = `${g.gateway}:${g.amount}`;
              if (g.payCode) seg += `-${g.payCode}`;
              if (g.couponId) seg += `-${g.couponId}`;
              return seg;
            })
            .join(","),
          coupon: this.selectedCardCoupon?.id
        }
      );
      // this.cards?.unshift(card);
      this.cards = await CardResource.query({ customer: this.customer.id });
      this.$emit("updated");
      this.selectedCardType = null;
      this.getCards();
      this.getCardPayments();
      watchPayments(card.payments || []).then(payments => {
        if (payments.length) {
          this.getCards();
          this.getCardPayments();
        }
      });
    } catch (e) {
      console.error(e.message);
    }
    this.submitLocked = false;
  }

  async activateCard(card: Card) {
    if (
      !(await confirm(
        "确认激活这张礼品卡",
        card.type === "balance"
          ? `将为 ${this.customer.name || this.customer.mobile} 增加账户余额 ${
              card.balance
            } 元`
          : "激活后不可转赠，储值卡将立即充值，按天数计有效期的时效卡和次卡将开始计算有效期"
      ))
    )
      return;
    await CardResource.save({ ...card, status: CardStatus.ACTIVATED });
    this.getCards();
    this.$emit("updated"); // should update user
  }

  async receiveGiftCard() {
    const giftCode = await promptInput("填写礼品码");
    if (!giftCode) return;
    // @ts-ignore
    await CardResource.save({ giftCode, customer: this.customer.id });
    this.getCards();
  }

  async enterCardCode() {
    const cardCode = await promptInput(
      "填写兑换码",
      "下方输入框光标激活时，可以使用扫码设备扫描兑换码"
    );
    if (!cardCode) return;
    // @ts-ignore
    await CardResource.save({ customer: this.customer.id }, { cardCode });
    this.getCards();
    this.getCardPayments();
  }

  selectCard(cardId: string) {
    if (cardId === this.selectedCardId) {
      this.selectedCardId = "";
    } else {
      this.selectedCardId = cardId;
    }
  }

  goToCustomer() {
    if (!this.customer) return;
    this.$router.push(`/user/${this.customer.id}`);
  }

  @Watch("loaded", { immediate: true }) onLoaded() {
    this.getCards();
    if (this.$user.can("PAYMENT")) {
      this.getCardPayments();
    }
  }

  @Watch("booking.status", { immediate: true }) async onBookingStatusUpdate(
    c: BookingStatus,
    p: BookingStatus
  ) {
    if (c && p && c !== p) {
      this.getCards();
    }
  }
}
