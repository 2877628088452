



















































































































import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Vue from "vue";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
// import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

@Component({
  components: {
    TopNavbar,
    ContentFooter,
    // MobileMenu,
    UserMenu
  }
})
export default class DashboardLayout extends Vue {
  sidebarBackgroundColor = "white";
  sidebarBackground = "primary";
  sidebarBackgroundImage = "";
  sidebarMini = false;
  sidebarImg = true;

  toggleSidebar() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.displaySidebar(false);
    }
  }
  minimizeSidebar() {
    if (this.$sidebar) {
      this.$sidebar.toggleMinimize();
    }
  }

  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  }

  // beforeRouteEnter(to: any, from: any, next: any) {
  //   console.log(to, from);
  //   next();
  // }

  @Watch("sidebarMini") onSidebarMiniUpdate() {
    this.minimizeSidebar();
  }

  get inDevelopment() {
    return process.env.NODE_ENV === "development";
  }
}
