<template>
  <md-card class="md-card-global-sales">
    <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
      <slot name="header"></slot>
    </md-card-header>

    <md-card-content :class="{ 'show-header': showHeader }">
      <slot name="content"></slot>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "global-sales-card",
  props: {
    headerColor: {
      type: String,
      default: ""
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .md-card-global-sales ::v-deep .md-table {
    padding: 0;
  }
}
.show-header ::v-deep table thead {
  display: table-header-group;
}
</style>
