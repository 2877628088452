import Sidebar from "./SideBar.vue";
import SidebarItem from "./SidebarItem.vue";
import Vue, { VueConstructor } from "vue";

const isMinimizedConfig = window.localStorage.getItem("sidebarMinimized");

export const store = Vue.observable({
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: isMinimizedConfig === "true"
});

if (store.isMinimized) {
  document.body.classList.toggle("sidebar-mini");
}

export const actions = {
  displaySidebar(value: boolean) {
    store.showSidebar = value;
  },
  toggleMinimize() {
    document.body.classList.toggle("sidebar-mini");
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);

    store.isMinimized = !store.isMinimized;
    window.localStorage.setItem(
      "sidebarMinimized",
      JSON.stringify(store.isMinimized)
    );
  }
};

const SidebarPlugin = {
  install(Vue: VueConstructor, options: any) {
    if (options && options.sidebarLinks) {
      store.sidebarLinks = options.sidebarLinks;
    }

    Object.defineProperty(Vue.prototype, "$sidebar", {
      get() {
        return { ...store, ...actions };
      }
    });
    Vue.component("side-bar", Sidebar);
    Vue.component("sidebar-item", SidebarItem);
  }
};

export default SidebarPlugin;
