import { PaymentResource } from "@/resources";
import { Payment } from "@/resources/interfaces";
import sleep from "./sleep";

export async function watchPayment(
  payment: Payment,
  state = { enabled: true }
) {
  while (state.enabled && payment.pending) {
    await sleep(500);
    payment = await PaymentResource.get({ id: payment.id });
  }
  return payment;
}

export function watchPayments(payments: Payment[], state = { enabled: true }) {
  const paymentsWatching = payments.filter(p => p.pending);
  return Promise.all(paymentsWatching.map(p => watchPayment(p, state)));
}
