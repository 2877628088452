





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Booking, User, BookingQuery, Scene } from "../resources/interfaces";
import { BookingResource } from "../resources";

@Component
export default class BookingsCard extends Vue {
  @Prop({ default: "预约记录" })
  title!: string;

  @Prop({ default: "play" })
  type!: Scene;

  @Prop()
  bookings?: Booking[];

  @Prop()
  customer?: User;

  @Prop()
  query?: BookingQuery;

  @Prop({ type: Boolean })
  hideIfEmpty?: boolean;

  get cardHeaderClass() {
    if (!this.type) return "";
    return "md-card-header-" + this.type;
  }

  get cardHeaderIcon() {
    if (!this.type) return "";
    const map = {
      [Scene.PLAY]: "timer",
      [Scene.EVENT]: "event",
      [Scene.GIFT]: "card_giftcard",
      [Scene.RETAIL]: "storefront",
      [Scene.FOOD]: "fastfood",
      [Scene.PARTY]: "cake",
      [Scene.CARE]: "child_care",
      [Scene.OTHER]: "extension",
      [Scene.CARD]: "card",
      [Scene.BALANCE]: "balance",
      [Scene.PERIOD]: "period",
      [Scene.MALL]: "shopping_cart"
    };
    return map[this.type];
  }

  get buttonClass() {
    if (!this.type) return "";
    return "md-" + this.type;
  }

  async mounted() {
    if (!this.bookings && this.query) {
      this.bookings = await BookingResource.query(this.query);
    }
  }

  showDetail(booking: Booking) {
    this.$router.push(`/booking/${booking.type}/${booking.id}`);
  }

  goCustomerBookings() {
    if (!this.customer) return;
    this.$router.push(`/booking/${this.type}?customer=${this.customer.id}`);
  }
}
