









import Vue from "vue";
import Component from "vue-class-component";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { http } from "@/resources";
import loadConfig from "./helpers/loadConfig";
import webSocket from "./helpers/webSocket";
import {
  detectAllFaces,
  TinyFaceDetectorOptions,
  loadTinyFaceDetectorModel
} from "face-api.js";
import { Config } from "./resources/interfaces";
import QrcodeScanner from "./components/QrcodeScanner.vue";
import { GlobalKeyEnter } from "./components";

@Component({ components: { QrcodeScanner, GlobalKeyEnter } })
export default class App extends Vue {
  pendingRequests = 0;
  configFetched = false;
  async created() {
    console.log("[App] Created.");
    http.interceptors.request.use(this.requestFullfilled);
    http.interceptors.response.use(
      this.responseFullfilled,
      this.responseRejected
    );

    webSocket.$on("open", () => {
      this.$webSocketConnected = true;
    });

    webSocket.$on("close", () => {
      this.$webSocketConnected = false;
    });

    // await this.loadConfig();

    this.$router.beforeResolve(async (to, from, next) => {
      console.log(`[App] Before resolve ${to.path}.`);

      if (to.path !== "/login") {
        await this.loadConfig(this.$config);
      }

      if (to.path !== "/login" && !window.localStorage.getItem("token")) {
        console.log("[App] No token, redirect to login.");
        return next({ path: "/login" });
      } else if (to.path === "/login" && window.localStorage.getItem("token")) {
        console.log("[App] Token exists, redirect to home.");
        return next({ path: "/" });
      }

      if (
        ["/", "/login"].includes(from.path) &&
        to.path === "/dashboard" &&
        this.$user.can("BOSSBOARD")
      ) {
        return next({ name: "Boss Board" });
      }

      if (
        ["/", "/login"].includes(from.path) &&
        to.path === "/dashboard" &&
        !this.$user.can("DASHBOARD")
      ) {
        return next({ name: "My Board" });
      }

      next();
    });

    this.$router.afterEach(async (to, from) => {
      if (to.name) {
        document.title = to.name + " - MINI MARS";
      }
    });
  }

  requestFullfilled(request: AxiosRequestConfig) {
    this.pendingRequests++;

    // stop request and return 401 response when no token exist except for login request
    if (
      !["auth/login", "config", "store"].includes(request.url || "") &&
      !window.localStorage.getItem("token")
    ) {
      this.pendingRequests--;
    }
    return request;
  }

  responseFullfilled(response: AxiosResponse) {
    this.pendingRequests--;
    return response;
  }

  responseRejected(err: any) {
    // console.log("App.responseRejected", err);
    this.pendingRequests--;
    const { response } = err;
    if (!response) {
      if (err.message === "Network Error") {
        const message = "网络连接错误";
        this.$notify({
          message,
          icon: "add_alert",
          type: "danger"
        });
      }
      return Promise.reject(err);
    }
    if (response.status >= 500) {
      const message = "服务器内部错误";
      this.$notify({
        message,
        icon: "add_alert",
        type: "danger"
      });
      return Promise.reject(new Error(message));
    } else if (response.status >= 400) {
      const message = response.data.message || response.statusText;
      this.$notify({
        message: message.length > 100 ? "发生错误，详见弹出窗口" : message,
        icon: "add_alert",
        type: "warning"
      });
      return Promise.reject(new Error(message));
    }
    return response;
  }

  async prepareFaceDetection() {
    if (this.$user.can("BOOKING_ALL_STORE")) return;
    await loadTinyFaceDetectorModel("https://cdn.mini-mars.com/face-models/");
    console.log("Model loaded.");
    const prepareImage = document.createElement("img");
    prepareImage.src = "/img/face-test.jpg";
    prepareImage.setAttribute("style", "display:none");
    document.querySelector("body")?.appendChild(prepareImage);
    await detectAllFaces(prepareImage, new TinyFaceDetectorOptions());
    console.log("Face detection prepared.");
    prepareImage.remove();
  }

  async loadConfig(configLoaded?: Config) {
    this.$config = await loadConfig(configLoaded);
    console.log(`[App] Config loaded.`);
    // this.prepareFaceDetection();
  }
}
