
















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  User,
  PaymentGatewayGroup,
  PaymentGateway,
  Scene,
  Coupon
} from "@/resources/interfaces";
import eventBus from "@/helpers/eventBus";

@Component
export default class PaymentDialog extends Vue {
  @Prop({ default: "" })
  title!: string;

  @Prop()
  user!: User | undefined;

  @Prop()
  amount!: number;

  @Prop()
  nonSpecialOfferAmount!: number;

  @Prop({ type: Boolean, default: true })
  allowBalance!: boolean;

  @Prop({ type: Boolean, default: false })
  useBalance!: boolean;

  @Prop({ type: Boolean, default: false })
  submitLock!: boolean;

  @Prop()
  scene?: Scene;

  @Prop()
  gateways?: PaymentGateway[];

  @Prop()
  cardSlug?: string;

  payCode = "";

  coupon: Coupon | null = null;

  get availablePaymentGateways() {
    let gateways = this.gateways || [
      PaymentGateway.Scan,
      PaymentGateway.Shouqianba,
      PaymentGateway.Cash,
      PaymentGateway.Pos,
      PaymentGateway.Pr
    ];
    if ([Scene.PARTY].includes(this.scene as Scene)) {
      gateways = gateways.filter(g => g !== PaymentGateway.Agency);
    }
    if (
      [Scene.PARTY, Scene.CARD, Scene.BALANCE, Scene.PERIOD].includes(
        this.scene as Scene
      ) &&
      !gateways.includes(PaymentGateway.Coupon)
    ) {
      gateways.push(PaymentGateway.Coupon);
    }
    if (this.$user.store) {
      gateways = gateways.filter(g => g !== PaymentGateway.Pr);
    }
    if (this.cardSlug?.match(/^com-/)) {
      gateways = [PaymentGateway.Cr];
    }
    return gateways.filter(
      g => !this.paymentGroups.map(g => g.gateway).includes(g)
    );
  }

  get availableCoupons() {
    return this.$coupons.filter(
      c =>
        c.scene === this.scene &&
        (!this.cardSlug ||
          !c.productNos?.length ||
          c.productNos.includes(this.cardSlug))
    );
  }

  get balancePaymentAmount() {
    if (!this.useBalance) return 0;
    return +Math.min(
      this.user?.balance || 0,
      // this.nonSpecialOfferAmount,
      this.amount
    ).toFixed(2);
  }

  get extraPaymentAmount() {
    return +(this.amount - this.balancePaymentAmount).toFixed(2);
  }

  get paymentGroupSumAmount() {
    return +this.paymentGroups
      .reduce((sum, group) => +(sum + (group.amount || 0)).toFixed(10), 0)
      .toFixed(2);
  }

  get valid() {
    return (
      !this.submitLock &&
      this.paymentGroups.every(g => !g.amount || g.gateway) &&
      this.extraPaymentAmount === this.paymentGroupSumAmount &&
      (!this.paymentGroups.some(g => g.gateway === PaymentGateway.Scan) ||
        this.payCode) &&
      (!this.paymentGroups.some(g => g.gateway === PaymentGateway.Coupon) ||
        this.coupon)
    );
  }

  onGlobalKeyEnter(key: string) {
    console.log("PaymentDialog:onGlobalKeyEnter", key);
  }

  paymentGroups: Partial<PaymentGatewayGroup>[] = [];

  addPaymentGroup() {
    this.paymentGroups.push({
      gateway: this.paymentGroups.length
        ? undefined
        : PaymentGateway.Shouqianba,
      amount: this.extraPaymentAmount - this.paymentGroupSumAmount
    });
  }

  removePaymentGroup(gateway: PaymentGateway) {
    this.paymentGroups = this.paymentGroups.filter(g => g.gateway !== gateway);
  }

  useCoupon(coupon?: Coupon) {
    if (coupon && this.usingCoupon(coupon)) {
      this.useCoupon();
    } else {
      this.coupon = coupon || null;
    }
  }

  usingCoupon(coupon: Coupon) {
    return this.coupon?.id === coupon.id;
  }

  openQrcodeScanner() {
    eventBus.$emit("openQrcodeScanner");
  }

  confirm() {
    console.log("PaymentDialog:confirm");
    if (this.paymentGroups[0]?.gateway) {
      window.localStorage.setItem(
        "defaultPaymentGateway",
        this.paymentGroups[0]?.gateway
      );
    }
    if (!this.valid) return;
    const scanGroup = this.paymentGroups.find(
      g => g.gateway === PaymentGateway.Scan
    );
    if (scanGroup && this.payCode) {
      scanGroup.payCode = this.payCode;
      this.payCode = "";
    }
    const couponGroup = this.paymentGroups.find(
      g => g.gateway === PaymentGateway.Coupon
    );
    if (couponGroup && this.coupon) {
      couponGroup.couponId = this.coupon.id;
      this.coupon = null;
    }
    this.$emit("confirm", this.paymentGroups);
  }

  cancel() {
    this.$emit("cancel");
    eventBus.$emit("closeQrcodeScanner");
  }

  onStringScanned(s: string) {
    if (!s || !this.paymentGroups.some(g => g.gateway === "scan")) return;
    // ((this.$refs.payCode as Vue).$el as HTMLElement).focus();
    this.payCode = s;
    setTimeout(() => {
      this.payCode = "";
    }, 2e4);
    this.confirm();
  }

  created() {
    console.log("PaymentDialog:created");
  }

  mounted() {
    if (this.extraPaymentAmount) {
      this.paymentGroups.push({
        gateway: this.availablePaymentGateways[0],
        amount: this.extraPaymentAmount
      });
    }
    eventBus.$on(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }

  destroyed() {
    eventBus.$off(["stringScanned", "qrcodeScanned"], this.onStringScanned);
  }
}
