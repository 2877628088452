




















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Payment } from "../resources/interfaces";

@Component
export default class PaymentsCard extends Vue {
  @Prop({ default: "付款记录" })
  title!: string;

  @Prop({ default: () => [] })
  payments!: Payment[];

  @Prop({ default: () => {} })
  pay!: Function;

  @Prop({ type: Boolean, default: false })
  hideTitle!: boolean;

  @Prop({ type: Boolean, default: false })
  hideTime!: boolean;
}
