




import Vue from "vue";
import Component from "vue-class-component";
import eventBus from "@/helpers/eventBus";

@Component
export default class GlobalKeyEnter extends Vue {
  cachedKeys = "";
  cacheGlobalKeydown(e: KeyboardEvent) {
    const srcElement = e.srcElement as HTMLElement;
    if (
      srcElement.nodeName !== "BODY" &&
      !Array.from(srcElement.classList).includes("md-dialog-container")
    ) {
      return;
    }
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    // console.log("[DEBUG] Key event", e.key, e.srcElement);
    if (
      e.key.match(/^[0-9a-zA-Z]{1}$/) &&
      !e.metaKey &&
      !e.ctrlKey &&
      !e.altKey
    ) {
      this.cachedKeys += e.key;
      // console.log(this.cachedKeys);
    } else if (e.key === "Enter") {
      this.$emit("input", this.cachedKeys);
      eventBus.$emit("stringScanned", this.cachedKeys);
      this.cachedKeys = "";
    } else if (e.key === "Escape") {
      this.cachedKeys = "";
    } else if (e.key === "Backspace" && this.cachedKeys) {
      this.cachedKeys = this.cachedKeys.substr(0, this.cachedKeys.length - 1);
    } else if (e.key === " " && this.cachedKeys) {
      this.cachedKeys = "";
      e.preventDefault();
    }
  }
  created() {
    console.log("GlobalKeyEnter bind:keydown");
    document.addEventListener("keydown", this.cacheGlobalKeydown);
  }
  destroyed() {
    document.removeEventListener("keydown", this.cacheGlobalKeydown);
  }
}
