












import eventBus from "@/helpers/eventBus";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Html5Qrcode } from "html5-qrcode";

@Component
export default class QrcodeScanner extends Vue {
  showQrcodeScanner = false;
  closeOnScanned = true;
  qrScanner?: Html5Qrcode;
  async openQrcodeScanner(closeOnScanned = true) {
    this.showQrcodeScanner = true;
    this.closeOnScanned = closeOnScanned;
    await this.$nextTick();
    this.qrScanner = new Html5Qrcode("qrcode-reader");
    this.qrScanner
      .start(
        { facingMode: "environment" },
        {
          fps: 10 // Optional, frame per seconds for qr code scanning
          // qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
        },
        (decodedText, decodedResult) => {
          // do something when code is read
          console.log("QR Result:", decodedText, decodedResult);
          this.onQrcodeResult(decodedText);
        },
        errorMessage => {
          // parse error, ignore it.
        }
      )
      .catch(err => {
        // Start failed, handle it.
        console.error(err);
      });
  }
  closeQrcodeScanner() {
    if (!this.showQrcodeScanner) return;
    this.showQrcodeScanner = false;
    if (!this.qrScanner) return;
    this.qrScanner.stop();
    // this.qrScanner.destroy();
  }
  onQrcodeResult(s: string) {
    if (!this.showQrcodeScanner) return;
    console.log("qrcode:", s);
    if (this.closeOnScanned) {
      this.showQrcodeScanner = false;
      if (!this.qrScanner) return;
      this.qrScanner.stop();
      // this.qrScanner.destroy();
    }
    eventBus.$emit("qrcodeScanned", s);
  }
  mounted() {
    eventBus.$on(
      "openQrcodeScanner",
      (
        { closeOnScanned }: { closeOnScanned: boolean } = {
          closeOnScanned: true
        }
      ) => {
        this.openQrcodeScanner(closeOnScanned);
      }
    );
    eventBus.$on("closeQrcodeScanner", () => {
      this.closeQrcodeScanner();
    });
  }
}
