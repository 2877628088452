import Vue from "vue";

const url = process.env.VUE_APP_WEBSOCKET_URL;

if (!url) throw new Error("missing_websocket_url");

const container: { socket?: WebSocket } = {
  socket: undefined
};

const emitter = new Vue({
  methods: {
    send(message: string) {
      if (container.socket?.readyState === WebSocket.OPEN)
        container.socket.send(message);
    },
    close() {
      if (container.socket?.readyState === WebSocket.OPEN)
        container.socket.close();
    }
  }
});

export const connect = () => {
  if (container.socket?.readyState === WebSocket.OPEN) return;
  const token = window.localStorage.getItem("token");
  if (!token) return;
  container.socket = new WebSocket(url + "?token=" + token);
  container.socket.onopen = () => {
    emitter.$emit("open");
    console.log("[WSK] WebSocket opened.");
  };

  container.socket.onmessage = (msg: any) => {
    emitter.$emit("message", msg.data);
    if (msg.data.startsWith("PING/")) {
      container.socket?.send(msg.data.replace(/^PING\//, "PONG/"));
    }
  };

  container.socket.onerror = (ev: Event) => {
    emitter.$emit("error", ev);
    console.log("[WSK] WebSocket errored.");
  };

  container.socket.onclose = (ev: CloseEvent) => {
    emitter.$emit("close", ev);
    console.log("[WSK] WebSocket closed, reconnecting...");
    setTimeout(() => {
      connect();
    }, 1000);
  };
};

export default emitter;
